import React, { useContext } from "react"
import { graphql } from "gatsby"
import MiniHero from "components/miniHero"

import Form from "components/form"
import SEO from "components/seo"

export default ({ data }) => {

    const {
        forms: {
            edges: forms
        },
        page: {
            title,
            flexibleContent: {
                form
            }
        }
    } = data.siteFields

    const _form = forms.find(loopedform => {
        return loopedform.node.id === form.id
    })

    return (
        <>
            <SEO title={title} />
            <div className="form-page">
                <MiniHero 
                    title={'Scope your project'} 
                    preTitle={title} 
                />
                <div className="container">
                    <Form id={form.id}/>
                </div>
            </div>
        </>
    )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
              databaseId
              title
              flexibleContent {
                  form {
                      ... on WPGraphQL_Form {
                          id
                      }
                  }
              }
            }
            forms {
                edges {
                    node {
                        id
                        title
                    }
                }
            }
        }
    }
`